.font-size{
    font-size: 17px;
} 

button.btn{
    background-color: #7E014D;
}

.color-change button.btn:hover{
    background-color: #040002;
}