.contact-details {
    display: flex;
    flex-direction: column;
    gap: 1rem; 
  }
  
  .detail-item {
    display: flex;
    align-items: center;
    gap: 0.5rem; 
  }
  
  
  
  .text {
    font-weight: bold;
  }
  
 
  