.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns with equal width */
    gap: 20px; /* Gap between grid items */
    justify-content: center; /* Center the grid items horizontally */
    padding: 0 80px; /* Add padding to both sides */
  }
  
  .grid-item {
    /* Style for each grid item */
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
  }
  
  .grid-item img {
    max-width: 100%; /* Ensure images don't exceed their container */
  }
  
  .title {
    margin-top: 10px; /* Margin above the title */
   
  }
  .title:hover{
    -webkit-text-fill-color: #7E014D;
  
    
  }

  .font-size{
    font-size:x-small;
    text-decoration: none !important;
    color: black;
  }




  