.position-box {
    border-radius: 10px;
    padding: 5px;
    border: 4px solid #b30047;
    box-shadow: #3300b3 !important;
  }
  
  .position-details {
    display: flex;
    justify-content: space-between;
  }
  
  .position-name {
    font-weight: bold;
  }
  
  .position-posts {
    font-style: italic;
    color: #ff0000; /* Change the color as needed */
    animation: flicker 1s infinite alternate; /* Add flickering animation */
  }
  
  @keyframes flicker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .text-dan{
    
    color: #ff0202;
    
  }

  