/* Navbar.css */

.navbar__categories {
    display: flex; /* Make the list items display horizontally */
    list-style: none; /* Remove the default list style (dots) */
  }
  
  .navbar__categories li {
    margin-right: 40px; /* Add some spacing between list items */

  }
  .navbar__cart{
    margin-right: 40px;
  }

  /* .navbar {
  
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  } */

  /* If using Bootstrap's btn class: */
.navbar__categories button.btn.outline-none {
  outline: none;
}

  .navbar__categories button:hover{
    -webkit-text-fill-color: rgb(29, 38, 222);
  }

  .navbar-nav {
    /* Customize button spacing, padding, etc. */
    display: flex; /* Ensure buttons fill the space horizontally */
    justify-content: space-between; /* Distribute buttons evenly */
  }

 


  .dropdown-menu {
    display: none;
    position: absolute;
    z-index: 1000; /* Ensure dropdown is above other content */
  }

  .dropdown-menu button{
    font-size: 14px;
    white-space: nowrap;
  }
  
  .dropdown-menu.show {
    display: block;
  }
  
 .navbar__category .dropdown-toggle::after {
  display: none !important;
}
 
  

 .admin-button button:hover{
      background-color: blue;
 }

 .logout-button button.btn{
       background-color: blue;
       
 }

 td button.remcol{
  background-color: rgb(159, 9, 9);
  
 }

 td button.remcol:hover{
  background-color: rgb(13, 1, 1);
  
 }
 
 .adddept-button button.btn{
   background-color: blue;
   justify-content: right;
 }


 /* ////////////////////////////////////////////////////////// */
 
 .navbar-toggler-wrapper {
  margin-right: 10px; /* Adjust this value as needed */
}

  .car-form input {
    border: 1px solid #11c329; /* Set border style, color, and width */
    border-radius: 5px; /* Optional: Add rounded corners */
    padding: 8px; /* Optional: Add padding for better appearance */
  }
  .car-form select {
    border: 1px solid #11c329; /* Set border style, color, and width */
    border-radius: 5px; /* Optional: Add rounded corners */
    padding: 8px; /* Optional: Add padding for better appearance */
  }

  .word-outline {
    font-weight: bold;
    color: #c81212; /* Set the text color */
    -webkit-text-stroke-width: 1px; /* Set the width of the outline */
    -webkit-text-stroke-color: #f7f3f3; /* Set the color of the outline */
  }
  
  .logo {
    max-width: 100px;
    margin-left: -80px; /* Default for larger screens */
  }
  
  /* Adjustments for mobile devices */
  @media (max-width: 576px) {
    .logo {
      margin-left: 0; /* Center or slightly adjust for mobile */
      padding-left: 10px; /* Add padding if needed */
    }
  }
  /* Small devices (tablets, 576px and up) */
@media (max-width: 992px) {
  .logo {
    margin-left: 0; /* Center or slightly adjust for small screens */
    padding-left: 10px;
  }
}
@media (max-width: 1095px) {
  .logo {
    margin-left: 0; /* Center or slightly adjust for small screens */
    padding-left: 10px;
  }
}


  
  