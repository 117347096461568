.form-control{
    border-width: 1px; /* Increase border thickness */
    border-color: #0d0101d5; /* Set border color */
    
}

.backform{
    background-color: #3B566A;
}

